"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseSemestersForSelect = exports.parseAcademicDegreesForSelect = exports.parseContractAndAnnexCorrection = exports.parseCreateUpdateContractAndAnnex = exports.parseGetContractAndAnnex = exports.parseEmployeeSignatureAndApprove = exports.academicDegrees = exports.parseEmployeeFamilyMember = exports.parseEducationSecondaries = exports.parseEducationProfessionals = exports.parseEducationHighs = exports.parseEmployeeDocumentFiles = exports.parseEmployee = exports.parseEmployees = exports.parseEmployeesForStructuralUnitSelect = exports.parseEmployeeForSelect = exports.parseEmployeesForSelectByPosition = exports.parseEmployeesForSelectWithProjects = exports.parseEmployeesForSelect = void 0;
const types_1 = require("api/references/employees/types");
const types_2 = require("api/documents/purchases/invoices/types");
const types_3 = require("api/references/structuralUnitsUpgraded/types");
const helper_1 = require("api/helper");
const contractAndAnnex_1 = require("src/modules/references/employees/store/contractAndAnnex");
const parsers_1 = require("api/files/parsers");
const parsers_2 = require("api/parsers/parsers");
const parseEmployeesForSelect = (response) => {
    if (response.content === null)
        return [];
    const employees = response.content;
    try {
        return employees.data.map(el => {
            const email = el.attributes.email ? ' (' + el.attributes.email + ')' : '';
            return {
                id: el.id,
                label: el.attributes.firstname + ' ' + el.attributes.lastname + email,
                structuralUnits: el.relationships.accounts.data
                    .filter(account => account.relationships.structuralUnit !== null)
                    .map(unit => {
                    const structuralUnits = unit.relationships.structuralUnit;
                    return {
                        accountId: unit.id,
                        id: structuralUnits.data.id,
                        label: structuralUnits.data.attributes.name + (unit.relationships.vacation ? ' (იმყოფება შვებულებაში,'
                            + ` შემცვლელი: ${unit.relationships.vacation.data.relationships.substituteAccount.data.relationships.user.data.attributes.fullname})` : ''),
                        disabled: !!unit.relationships.vacation
                    };
                }) || [],
                email: el.attributes.email || el.attributes.personalEmail,
                phoneNumber: el.attributes.workPhoneNumber || el.attributes.mobileNumber
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseEmployeesForSelect = parseEmployeesForSelect;
const parseEmployeesForSelectWithProjects = (response) => {
    if (response.content === null)
        return [];
    const employees = response.content;
    try {
        return employees.data.map(el => {
            const email = el.attributes.email ? ' (' + el.attributes.email + ')' : '';
            return {
                id: el.id,
                label: el.attributes.firstname + ' ' + el.attributes.lastname + email,
                structuralUnits: el.relationships.accounts.data.map(unit => {
                    const structuralUnits = unit.relationships.structuralUnit;
                    const project = unit.relationships.project;
                    return {
                        accountId: unit.id,
                        id: structuralUnits ? structuralUnits.data.id : project.data.id,
                        label: (structuralUnits ? structuralUnits.data.attributes.name : 'პროექტის "' + project.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი')
                            + (unit.relationships.vacation ? ' (იმყოფება შვებულებაში,'
                                + ` შემცვლელი: ${unit.relationships.vacation.data.relationships.substituteAccount.data.relationships.user.data.attributes.fullname})` : ''),
                        disabled: !!unit.relationships.vacation
                    };
                }) || [],
                email: el.attributes.email || el.attributes.personalEmail,
                phoneNumber: el.attributes.workPhoneNumber || el.attributes.mobileNumber
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseEmployeesForSelectWithProjects = parseEmployeesForSelectWithProjects;
const parseEmployeesForSelectByPosition = (response) => {
    if (response.content === null)
        return [];
    const responseEmployees = response.content;
    try {
        const employees = [];
        responseEmployees.data.forEach(user => {
            const email = user.attributes.email ? ' (' + user.attributes.email + ')' : '';
            user.relationships.accounts.data.filter(el => !!el.relationships.structuralUnit).forEach(account => {
                const structuralUnit = account.relationships.structuralUnit;
                const label = user.attributes.firstname + ' ' + user.attributes.lastname + ' ' + email + ' - ' + structuralUnit.data.attributes.name;
                const employee = {
                    id: user.id + '-' + account.id,
                    label,
                    userId: user.id,
                    type: structuralUnit.data.attributes.abbr === 'head_of_administration' ? types_2.InvoiceSignatoryType.ADMINISTRATION : types_2.InvoiceSignatoryType.OTHERS,
                    structuralUnitId: structuralUnit.data.id
                };
                employees.push(employee);
            });
        });
        return employees;
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseEmployeesForSelectByPosition = parseEmployeesForSelectByPosition;
const parseEmployeeForSelect = (response) => {
    if (response.content === null)
        return null;
    const employee = response.content;
    try {
        const email = employee.data.attributes.email ? ' (' + employee.data.attributes.email + ')' : '';
        return {
            id: employee.data.id,
            label: employee.data.attributes.firstname + employee.data.attributes.lastname + email
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return null;
    }
};
exports.parseEmployeeForSelect = parseEmployeeForSelect;
const parseEmployeesForStructuralUnitSelect = (response) => {
    if (response.content === null)
        return [];
    const employees = response.content;
    try {
        return employees.data.map(el => {
            const identityNumber = el.attributes.identityNumber ? ' (' + el.attributes.identityNumber + ')' : '';
            return {
                id: el.id,
                label: el.attributes.firstname + ' ' + el.attributes.lastname + identityNumber,
                structuralUnits: el.relationships.accounts.data.map(unit => {
                    const structuralUnit = unit.relationships.structuralUnit;
                    const project = unit.relationships.project;
                    return {
                        accountId: unit.id,
                        id: (structuralUnit || project).data.id,
                        label: structuralUnit
                            ? structuralUnit.data.attributes.fullName
                            : 'პროექტის "' + project.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი'
                    };
                }) || [],
                email: el.attributes.email || el.attributes.personalEmail,
                phoneNumber: el.attributes.workPhoneNumber || el.attributes.mobileNumber
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseEmployeesForStructuralUnitSelect = parseEmployeesForStructuralUnitSelect;
const parseEmployees = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const employees = response.content;
    try {
        return {
            data: employees.data.map(el => {
                return {
                    id: el.id,
                    gender: el.attributes.gender,
                    genderName: el.attributes.gender !== null ? el.attributes.gender === types_1.EmployeeGender.MALE ? 'კაცი' : 'ქალი' : undefined,
                    email: el.attributes.email,
                    fullName: el.attributes.firstname + ' ' + el.attributes.lastname,
                    positions: el.relationships.accounts.data.map(unit => {
                        const structuralUnit = unit.relationships.structuralUnit;
                        const project = unit.relationships.project;
                        return {
                            id: (structuralUnit || project).data.id,
                            type: (structuralUnit || project).data.type,
                            isAuthorizedAccount: unit.attributes.IsAuthorizedAccount,
                            status: unit.attributes.status,
                            fullName: structuralUnit
                                ? structuralUnit.data.attributes.type === types_3.StructuralUnitType.STRUCTURAL_UNIT
                                    ? unit.attributes.position
                                    : structuralUnit.data.attributes.name
                                : 'პროექტის "' + project.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი',
                            positionStatus: structuralUnit?.data.attributes.positionStatus || types_3.PositionStatus.FREELANCE
                        };
                    }),
                    createdAt: (0, parsers_2.transformDate)(el.attributes.createdAt),
                    status: el.attributes.status
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_2.transformPagination)(employees.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseEmployees = parseEmployees;
const parseEmployee = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const employee = response.content;
    const country = employee.data.relationships.country;
    const administrativeBuilding = employee.data.relationships.administrativeBuilding?.data;
    const room = employee.data.relationships.administrativeBuildingRoom?.data;
    try {
        return {
            data: {
                id: employee.data.id,
                contactInfo: {
                    firstname: employee.data.attributes.firstname,
                    lastname: employee.data.attributes.lastname,
                    firstnameLatin: employee.data.attributes.firstnameEn,
                    lastnameLatin: employee.data.attributes.lastnameEn,
                    actualAddress: employee.data.attributes.actualAddress,
                    legalAddress: employee.data.attributes.legalAddress,
                    postIndex: employee.data.attributes.postIndex,
                    birthDate: (0, parsers_2.nullableTransformDate)(employee.data.attributes.birthDate),
                    age: employee.data.attributes.age,
                    email: employee.data.attributes.email,
                    gender: employee.data.attributes.gender,
                    idCardValidTo: (0, parsers_2.nullableTransformDate)(employee.data.attributes.idCardValidTo),
                    identityNumber: employee.data.attributes.identityNumber,
                    mobileNumber: employee.data.attributes.mobileNumber,
                    passportNumber: employee.data.attributes.passportNumber,
                    passportValidTo: employee.data.attributes.passportValidTo,
                    personalEmail: employee.data.attributes.personalEmail,
                    workPhoneNumber: employee.data.attributes.workPhoneNumber,
                    personalCaseNumber: employee.data.attributes.personalCaseNumber,
                    noWorkPlace: employee.data.attributes.noWorkPlace,
                    administrativeBuilding: administrativeBuilding && {
                        id: administrativeBuilding.id,
                        label: administrativeBuilding.attributes.name
                    } || null,
                    administrativeBuildingRoom: room && {
                        id: room.id,
                        label: room.attributes.number
                    } || null,
                    country: country && {
                        id: country.data.id,
                        label: country.data.attributes.nameKa || country.data.attributes.nameEn
                    },
                    foreignCitizen: employee.data.attributes.foreignCitizen ? types_1.Citizen.FOREIGN : types_1.Citizen.GEORGIAN,
                    residenceCertificateNumber: employee.data.attributes.residenceCertificateNumber,
                    residenceCertificateEndDate: (0, parsers_2.nullableTransformDate)(employee.data.attributes.residenceCertificateEndDate),
                    resident: employee.data.attributes.resident
                },
                familyMembers: employee.data.relationships.familyMembers.data.map(familyMember => {
                    return {
                        id: familyMember.id,
                        firstname: familyMember.attributes.firstname,
                        lastname: familyMember.attributes.lastname,
                        phone: familyMember.attributes.phone
                    };
                })
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseEmployee = parseEmployee;
const parseEmployeeDocumentFiles = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const employeeDocumentFiles = response.content;
    const mediaFiles = {
        cv: [],
        conviction: [],
        diploma: [],
        certificate: [],
        drivingLicense: [],
        bankDetailing: [],
        healthNotice: [],
        identityPassportResidence: [],
        taxBenefit: [],
        schoolCertificate: [],
        pwdNoticeType: [],
        other: []
    };
    const educationHighMediaFiles = {
        highDiploma: [],
        otherHighEducation: []
    };
    const educationSecondaryMediaFiles = {
        secondaryDiploma: [],
        otherSecondaryEducation: []
    };
    const educationProfessionalMediaFiles = {
        professionalDiploma: [],
        otherProfessionalEducation: []
    };
    const bankAccountMediaFiles = {
        bank: []
    };
    const trainingMediaFiles = {
        trainings: []
    };
    employeeDocumentFiles.data.relationships.mediaFiles.data.forEach(file => {
        switch (file.attributes.pivots.type) {
            case types_1.EmployeeDocumentFileTypes.CV:
                return mediaFiles.cv.push(parseEmployeeFile(file));
            case types_1.EmployeeDocumentFileTypes.CONVICTION:
                return mediaFiles.conviction.push(parseEmployeeFile(file));
            case types_1.EmployeeDocumentFileTypes.DRIVING_LICENSE:
                return mediaFiles.drivingLicense.push(parseEmployeeFile(file));
            case types_1.EmployeeDocumentFileTypes.HEALTH_NOTICE:
                return mediaFiles.healthNotice.push(parseEmployeeFile(file));
            case types_1.EmployeeDocumentFileTypes.IDENTITY_PASSPORT_RESIDENCE:
                return mediaFiles.identityPassportResidence.push(parseEmployeeFile(file));
            case types_1.EmployeeDocumentFileTypes.TAX_BENEFIT:
                return mediaFiles.taxBenefit.push(parseEmployeeFile(file));
            case types_1.EmployeeDocumentFileTypes.PWD_NOTICE_TYPE:
                return mediaFiles.pwdNoticeType.push(parseEmployeeFile(file));
            case types_1.EmployeeDocumentFileTypes.OTHER:
                return mediaFiles.other.push(parseEmployeeFile(file));
        }
    });
    employeeDocumentFiles.data.relationships.educationHighMediaFiles.data.forEach(file => {
        switch (file.attributes.pivots.type) {
            case types_1.EmployeeEducationFileTypes.DIPLOMA:
                return educationHighMediaFiles.highDiploma.push(parseEmployeeEducationFile(file));
            case types_1.EmployeeEducationFileTypes.OTHER:
                return educationHighMediaFiles.otherHighEducation.push(parseEmployeeEducationFile(file));
        }
    });
    employeeDocumentFiles.data.relationships.educationSecondaryMediaFiles.data.forEach(file => {
        switch (file.attributes.pivots.type) {
            case types_1.EmployeeEducationFileTypes.DIPLOMA:
                return educationSecondaryMediaFiles.secondaryDiploma.push(parseEmployeeEducationFile(file));
            case types_1.EmployeeEducationFileTypes.OTHER:
                return educationSecondaryMediaFiles.otherSecondaryEducation.push(parseEmployeeEducationFile(file));
        }
    });
    employeeDocumentFiles.data.relationships.educationProfessionalMediaFiles.data.forEach(file => {
        switch (file.attributes.pivots.type) {
            case types_1.EmployeeEducationFileTypes.DIPLOMA:
                return educationProfessionalMediaFiles.professionalDiploma.push(parseEmployeeEducationFile(file));
            case types_1.EmployeeEducationFileTypes.OTHER:
                return educationProfessionalMediaFiles.otherProfessionalEducation.push(parseEmployeeEducationFile(file));
        }
    });
    employeeDocumentFiles.data.relationships.bankAccountMediaFiles.data.forEach(file => {
        bankAccountMediaFiles.bank.push(parseBankAndTrainingFile(file));
    });
    employeeDocumentFiles.data.relationships.trainingMediaFiles.data.forEach(file => {
        trainingMediaFiles.trainings.push(parseBankAndTrainingFile(file));
    });
    try {
        return {
            data: {
                mediaFiles,
                educationHighMediaFiles,
                educationSecondaryMediaFiles,
                educationProfessionalMediaFiles,
                bankAccountMediaFiles,
                trainingMediaFiles
            },
            errors: null,
            status: response.status
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseEmployeeDocumentFiles = parseEmployeeDocumentFiles;
const parseEducationHighs = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const educationHighs = response.content;
    try {
        return {
            data: educationHighs.data.map(educationHigh => {
                const diploma = educationHigh.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_1.EmployeeEducationFileTypes.DIPLOMA).map(file => parseEmployeeEducationFile(file));
                const other = educationHigh.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_1.EmployeeEducationFileTypes.OTHER).map(file => parseEmployeeEducationFile(file));
                return {
                    id: educationHigh.id,
                    educationalInstitution: educationHigh.relationships.educationalInstitution && {
                        id: educationHigh.relationships.educationalInstitution.data.id,
                        label: educationHigh.relationships.educationalInstitution.data.attributes.name
                    },
                    diplomaNumber: educationHigh.attributes.diplomaNumber,
                    releaseDate: (0, parsers_2.nullableTransformDate)(educationHigh.attributes.releaseDate),
                    studyStartDate: (0, parsers_2.nullableTransformDate)(educationHigh.attributes.studyStartDate),
                    educationType: educationHigh.attributes.educationType,
                    faculty: educationHigh.attributes.faculty,
                    specialty: educationHigh.attributes.specialty,
                    dateOfAwardQualifications: (0, parsers_2.nullableTransformDate)(educationHigh.attributes.dateOfAwardQualifications),
                    assign: educationHigh.attributes.assign,
                    qualification: educationHigh.attributes.qualification,
                    academicDegrees: exports.academicDegrees.find(el => el.id === String(educationHigh.attributes.academicDegree)),
                    assignedDegree: educationHigh.attributes.assignedDegree,
                    titleOfMastersTopic: educationHigh.attributes.titleOfMastersTopic,
                    titleOfDoctoralTopic: educationHigh.attributes.titleOfDoctoralTopic,
                    files: {
                        diploma: diploma.length > 0 ? diploma : [{ ...parsers_1.initialFile, type: types_1.EmployeeEducationFileTypes.DIPLOMA }],
                        other: other.length > 0 ? other : [{ ...parsers_1.initialFile, type: types_1.EmployeeEducationFileTypes.OTHER }]
                    }
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseEducationHighs = parseEducationHighs;
const parseEducationProfessionals = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const educationProfessionals = response.content;
    try {
        return {
            data: educationProfessionals.data.map(educationProfessional => {
                const diploma = educationProfessional.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_1.EmployeeEducationFileTypes.DIPLOMA).map(file => parseEmployeeEducationFile(file));
                const other = educationProfessional.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_1.EmployeeEducationFileTypes.OTHER).map(file => parseEmployeeEducationFile(file));
                return {
                    id: educationProfessional.id,
                    educationalInstitution: educationProfessional.relationships.educationalInstitution && {
                        id: educationProfessional.relationships.educationalInstitution.data.id,
                        label: educationProfessional.relationships.educationalInstitution.data.attributes.name
                    },
                    diplomaNumber: educationProfessional.attributes.diplomaNumber,
                    releaseDate: (0, parsers_2.nullableTransformDate)(educationProfessional.attributes.releaseDate),
                    studyStartDate: (0, parsers_2.nullableTransformDate)(educationProfessional.attributes.studyStartDate),
                    educationType: educationProfessional.attributes.educationType,
                    dateOfAwardQualifications: (0, parsers_2.nullableTransformDate)(educationProfessional.attributes.dateOfAwardQualifications),
                    assign: educationProfessional.attributes.assign,
                    qualification: educationProfessional.attributes.qualification,
                    programName: educationProfessional.attributes.programName,
                    files: {
                        diploma: diploma.length > 0 ? diploma : [{ ...parsers_1.initialFile, type: types_1.EmployeeEducationFileTypes.DIPLOMA }],
                        other: other.length > 0 ? other : [{ ...parsers_1.initialFile, type: types_1.EmployeeEducationFileTypes.OTHER }]
                    }
                };
            }),
            errors: null,
            status: response.status
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseEducationProfessionals = parseEducationProfessionals;
const parseEducationSecondaries = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const educationSecondaries = response.content;
    try {
        return {
            data: educationSecondaries.data.map(educationSecondary => {
                const diploma = educationSecondary.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_1.EmployeeEducationFileTypes.DIPLOMA).map(file => parseEmployeeEducationFile(file));
                const other = educationSecondary.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_1.EmployeeEducationFileTypes.OTHER).map(file => parseEmployeeEducationFile(file));
                return {
                    id: educationSecondary.id,
                    educationalInstitution: educationSecondary.relationships.educationalInstitution && {
                        id: educationSecondary.relationships.educationalInstitution.data.id,
                        label: educationSecondary.relationships.educationalInstitution.data.attributes.name
                    },
                    diplomaNumber: educationSecondary.attributes.diplomaNumber,
                    releaseDate: (0, parsers_2.nullableTransformDate)(educationSecondary.attributes.releaseDate),
                    studyStartDate: (0, parsers_2.nullableTransformDate)(educationSecondary.attributes.studyStartDate),
                    educationType: educationSecondary.attributes.educationType,
                    graduationDate: (0, parsers_2.nullableTransformDate)(educationSecondary.attributes.graduationDate),
                    files: {
                        diploma: diploma.length > 0 ? diploma : [{ ...parsers_1.initialFile, type: types_1.EmployeeEducationFileTypes.DIPLOMA }],
                        other: other.length > 0 ? other : [{ ...parsers_1.initialFile, type: types_1.EmployeeEducationFileTypes.OTHER }]
                    }
                };
            }),
            errors: null,
            status: response.status
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseEducationSecondaries = parseEducationSecondaries;
const parseEmployeeFamilyMember = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const employee = response.content;
    try {
        return {
            data: employee.data.map(el => {
                return {
                    id: el.id,
                    firstname: el.attributes.firstname,
                    lastname: el.attributes.lastname,
                    phone: el.attributes.phone
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseEmployeeFamilyMember = parseEmployeeFamilyMember;
const parseEmployeeFile = (file) => {
    return {
        id: file.id,
        type: file.attributes.pivots.type,
        name: file.attributes.name,
        status: file.attributes.status,
        link: file.attributes.downloadUrl,
        originalName: file.attributes.originalName,
        extension: file.attributes.extension
    };
};
const parseEmployeeEducationFile = (file) => {
    return {
        id: file.id,
        status: file.attributes.status,
        type: file.attributes.pivots.type,
        name: file.attributes.name,
        link: file.attributes.downloadUrl,
        originalName: file.attributes.originalName,
        extension: file.attributes.extension
    };
};
const parseBankAndTrainingFile = (file) => {
    return {
        id: file.id,
        name: file.attributes.name,
        link: file.attributes.downloadUrl,
        status: file.attributes.status,
        originalName: file.attributes.originalName,
        extension: file.attributes.extension
    };
};
exports.academicDegrees = [
    { id: String(types_1.AcademicDegree.BACHELOR), label: 'ბაკალავრი' },
    { id: String(types_1.AcademicDegree.MASTER), label: 'მაგისტრი' },
    { id: String(types_1.AcademicDegree.EQ_MASTER), label: 'მაგისტრთან გათანაბრებული' },
    { id: String(types_1.AcademicDegree.DOCTOR), label: 'დოქტორი' },
    { id: String(types_1.AcademicDegree.EQ_DOCTOR), label: 'დოქტორთან გათანაბრებული' }
];
const parseEmployeeSignatureAndApprove = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const employeeDocumentFiles = response.content;
    const pendingFiles = employeeDocumentFiles.data.find(el => el.attributes.status === types_1.SignatoryFileStatus.PENDING);
    const activeFiles = employeeDocumentFiles.data.find(el => el.attributes.status === types_1.SignatoryFileStatus.ACTIVE);
    try {
        return {
            data: {
                pending: pendingFiles ? {
                    id: pendingFiles.id,
                    isUsed: pendingFiles.attributes.isUsed,
                    updatedAt: (0, parsers_2.transformDateTime)(pendingFiles.attributes.updatedAt),
                    signature: parseEmployeeSignatureFile(pendingFiles.relationships.signature?.data),
                    signatureApprove: parseEmployeeSignatureFile(pendingFiles.relationships.document?.data)
                } : null,
                active: activeFiles ? {
                    id: activeFiles.id,
                    isUsed: activeFiles.attributes.isUsed,
                    updatedAt: (0, parsers_2.transformDateTime)(activeFiles.attributes.updatedAt),
                    signature: parseEmployeeSignatureFile(activeFiles.relationships.signature.data),
                    signatureApprove: parseEmployeeSignatureFile(activeFiles.relationships.document.data)
                } : null,
                status: pendingFiles ? pendingFiles.attributes.status : activeFiles ? activeFiles.attributes.status : null,
                signatureAndSignatureApproveFiles: employeeDocumentFiles.data.filter(el => el.attributes.status === types_1.SignatoryFileStatus.PASSIVE).map(el => {
                    return {
                        id: el.id,
                        isUsed: el.attributes.isUsed,
                        signatureApprove: parseEmployeeSignatureFile(el.relationships.document.data),
                        signatory: parseEmployeeSignatureFile(el.relationships.signature.data),
                        updatedAt: (0, parsers_2.transformDateTime)(el.attributes.updatedAt),
                        visualStatus: parseEmployeeSignatoryVisualStatuses(el.attributes.status)
                    };
                })
            },
            errors: null,
            status: response.status
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseEmployeeSignatureAndApprove = parseEmployeeSignatureAndApprove;
const parseEmployeeSignatureFile = (file) => {
    if (!file)
        return null;
    return {
        id: file.id,
        name: file.attributes.name,
        link: file.attributes.downloadUrl,
        originalName: file.attributes.originalName,
        status: file.attributes.status,
        extension: file.attributes.extension,
        updatedAt: (0, parsers_2.transformDateTime)(file.attributes.updatedAt)
    };
};
const parseEmployeeSignatoryVisualStatuses = (status) => {
    switch (status) {
        case types_1.SignatoryFileStatus.ACTIVE:
            return { name: 'აქტიური', color: 'success' };
        case types_1.SignatoryFileStatus.PASSIVE:
            return { name: 'პასიური', color: 'secondary' };
        case types_1.SignatoryFileStatus.PENDING:
            return { name: 'მიმდინარე', color: 'warning' };
    }
};
const parseGetContractAndAnnex = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const contractAndAnnex = response.content;
    try {
        return {
            data: contractAndAnnex ? contractAndAnnex.data.map(contract => parseContractAndAnnex(contract)) : contractAndAnnex_1.initialContractAndAnnex.data,
            errors: null,
            status: response.status
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseGetContractAndAnnex = parseGetContractAndAnnex;
const parseCreateUpdateContractAndAnnex = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const contractAndAnnex = response.content.data;
    try {
        return {
            data: contractAndAnnex ? parseContractAndAnnex(contractAndAnnex) : null,
            errors: null,
            status: response.status
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCreateUpdateContractAndAnnex = parseCreateUpdateContractAndAnnex;
const parseContractAndAnnexCorrection = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const correction = response.content.data;
    const contractCorrectionFile = correction?.relationships.contractMediaFile?.data;
    const annexCorrectionFile = correction?.relationships.attachmentMediaFile?.data;
    const combinedCorrectionPdfUid = correction?.relationships.combinedPdfMediaFile?.data.id;
    const latestSignatoryFileId = correction?.relationships.latestSignatory?.data.relationships.pdfMediaFile.data.id;
    const correctionSemesterInfo = correction.attributes.semesterInfo;
    try {
        return {
            data: correction ? {
                id: correction.id,
                status: correction.attributes.status,
                contract: contractCorrectionFile ? parseMediaFile(contractCorrectionFile) : parsers_1.initialFile,
                annex: annexCorrectionFile ? parseMediaFile(annexCorrectionFile) : parsers_1.initialFile,
                contractFileUid: latestSignatoryFileId || combinedCorrectionPdfUid || null,
                commentQuantity: correction.attributes.comment_quantity,
                hash: correction.attributes.hash,
                startDate: (0, parsers_2.nullableTransformDate)(correction.attributes.startDate),
                endDate: (0, parsers_2.nullableTransformDate)(correction.attributes.endDate),
                isActive: parseContractDateVisualStatus(correction.attributes.isActive),
                creatorAccountId: correction.relationships.creator.data.id,
                semester: correctionSemesterInfo.semesterId && correctionSemesterInfo.semesterName
                    ? {
                        id: correctionSemesterInfo.semesterId,
                        label: correctionSemesterInfo.semesterName
                    }
                    : null,
                academicDegree: correctionSemesterInfo.degreeId && correctionSemesterInfo.degreeName
                    ? {
                        id: correctionSemesterInfo.degreeId,
                        label: correctionSemesterInfo.degreeName
                    }
                    : null
            } : null,
            errors: null,
            status: response.status
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseContractAndAnnexCorrection = parseContractAndAnnexCorrection;
const parseContractAndAnnex = (contractAndAnnex) => {
    const contractFile = contractAndAnnex.relationships.contractMediaFile?.data;
    const annexFile = contractAndAnnex.relationships.attachmentMediaFile?.data;
    const combinedPdfUid = contractAndAnnex.relationships.combinedPdfMediaFile?.data.id;
    const latestSignatoryFileId = contractAndAnnex.relationships.latestSignatory?.data.relationships.pdfMediaFile.data.id;
    const activeCorrection = contractAndAnnex.relationships.corrections.data.find(correction => correction.attributes.status !== types_1.ContractAndAnnexStatuses.SIGNED);
    const activeCorrectionSemesterInfo = activeCorrection?.attributes.semesterInfo;
    const passiveCorrections = contractAndAnnex.relationships.corrections.data.filter(correction => correction.attributes.status === types_1.ContractAndAnnexStatuses.SIGNED);
    const semesterInfo = contractAndAnnex.attributes.semesterInfo;
    return {
        id: contractAndAnnex.id,
        contract: contractFile ? parseMediaFile(contractFile) : parsers_1.initialFile,
        annex: annexFile ? parseMediaFile(annexFile) : parsers_1.initialFile,
        status: contractAndAnnex.attributes.status,
        creatorAccountId: contractAndAnnex.relationships.creator.data.id,
        hash: contractAndAnnex.attributes.hash,
        commentQuantity: contractAndAnnex.attributes.comment_quantity,
        contractFileUid: latestSignatoryFileId || combinedPdfUid || null,
        startDate: (0, parsers_2.nullableTransformDate)(contractAndAnnex.attributes.startDate),
        endDate: (0, parsers_2.nullableTransformDate)(contractAndAnnex.attributes.endDate),
        isActive: parseContractDateVisualStatus(contractAndAnnex.attributes.isActive),
        academicDegree: semesterInfo.degreeId && semesterInfo.degreeName
            ? {
                id: semesterInfo.degreeId,
                label: semesterInfo.degreeName
            }
            : null,
        semester: semesterInfo.semesterId && semesterInfo.semesterName
            ? {
                id: semesterInfo.semesterId,
                label: semesterInfo.semesterName
            }
            : null,
        activeCorrection: activeCorrection
            ? {
                id: activeCorrection.id,
                contract: activeCorrection.relationships.contractMediaFile?.data ? parseMediaFile(activeCorrection.relationships.contractMediaFile?.data) : parsers_1.initialFile,
                annex: activeCorrection.relationships.attachmentMediaFile?.data ? parseMediaFile(activeCorrection.relationships.attachmentMediaFile?.data) : parsers_1.initialFile,
                status: activeCorrection.attributes.status,
                hash: activeCorrection.attributes.hash,
                creatorAccountId: activeCorrection.relationships.creator.data.id,
                commentQuantity: activeCorrection.attributes.comment_quantity,
                contractFileUid: activeCorrection.relationships.latestSignatory?.data.relationships.pdfMediaFile.data.id
                    || activeCorrection.relationships.combinedPdfMediaFile?.data.id
                    || null,
                startDate: (0, parsers_2.nullableTransformDate)(activeCorrection.attributes.startDate),
                endDate: (0, parsers_2.nullableTransformDate)(activeCorrection.attributes.endDate),
                isActive: parseContractDateVisualStatus(activeCorrection.attributes.isActive),
                semester: activeCorrectionSemesterInfo?.semesterId && activeCorrectionSemesterInfo?.semesterName
                    ? {
                        id: activeCorrectionSemesterInfo?.semesterId,
                        label: activeCorrectionSemesterInfo?.semesterName
                    }
                    : null,
                academicDegree: activeCorrectionSemesterInfo?.degreeId && activeCorrectionSemesterInfo?.degreeName
                    ? {
                        id: activeCorrectionSemesterInfo?.degreeId,
                        label: activeCorrectionSemesterInfo?.degreeName
                    }
                    : null
            } : {
            id: null,
            contract: parsers_1.initialFile,
            annex: parsers_1.initialFile,
            status: types_1.ContractAndAnnexStatuses.DRAFT,
            hash: '',
            creatorAccountId: null,
            commentQuantity: 0,
            contractFileUid: null,
            startDate: null,
            endDate: null,
            isActive: parseContractDateVisualStatus(null),
            semester: null,
            academicDegree: null
        },
        corrections: passiveCorrections.map(correction => {
            const correctionSemesterInfo = correction.attributes.semesterInfo;
            const correctionContractFile = correction.relationships.contractMediaFile?.data;
            const correctionAnnexFile = correction.relationships.attachmentMediaFile?.data;
            const correctionCombinedPdfUid = correction.relationships.combinedPdfMediaFile?.data.id;
            const correctionLatestSignatoryFileId = correction.relationships.latestSignatory?.data.relationships.pdfMediaFile.data.id;
            return {
                id: correction.id,
                contract: correctionContractFile ? parseMediaFile(correctionContractFile) : parsers_1.initialFile,
                annex: correctionAnnexFile ? parseMediaFile(correctionAnnexFile) : parsers_1.initialFile,
                creatorAccountId: correction.relationships.creator.data.id,
                status: correction.attributes.status,
                hash: correction.attributes.hash,
                commentQuantity: correction.attributes.comment_quantity,
                contractFileUid: correctionLatestSignatoryFileId || correctionCombinedPdfUid || null,
                startDate: (0, parsers_2.nullableTransformDate)(correction.attributes.startDate),
                endDate: (0, parsers_2.nullableTransformDate)(correction.attributes.endDate),
                isActive: parseContractDateVisualStatus(correction.attributes.isActive),
                semester: correctionSemesterInfo.semesterId && correctionSemesterInfo.semesterName
                    ? {
                        id: correctionSemesterInfo.semesterId,
                        label: correctionSemesterInfo.semesterName
                    }
                    : null,
                academicDegree: correctionSemesterInfo.degreeId && correctionSemesterInfo.degreeName
                    ? {
                        id: correctionSemesterInfo.degreeId,
                        label: correctionSemesterInfo.degreeName
                    }
                    : null
            };
        })
    };
};
const parseMediaFile = (file) => {
    return {
        id: file.id,
        status: file.attributes.status,
        name: file.attributes.name,
        link: file.attributes.downloadUrl,
        originalName: file.attributes.originalName,
        extension: file.attributes.extension
    };
};
const parseContractDateVisualStatus = (status) => {
    switch (status) {
        case true:
            return { name: 'აქტიური', color: 'success' };
        case false:
            return { name: 'პასიური', color: 'muted' };
        case null:
            return { name: '', color: '' };
    }
};
const parseAcademicDegreesForSelect = (response) => {
    const academicDegree = response.content;
    try {
        return academicDegree.data.map(degree => {
            return {
                id: degree.attributes.id,
                label: degree.attributes.name,
                semesters: (0, exports.parseSemestersForSelect)(degree.relationships.semesters.data)
            };
        });
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return [];
    }
};
exports.parseAcademicDegreesForSelect = parseAcademicDegreesForSelect;
const parseSemestersForSelect = (semesters) => {
    return semesters.map(semester => {
        return {
            id: semester.attributes.id,
            label: semester.attributes.name,
            startDate: (0, parsers_2.nullableTransformDate)(semester.attributes.dates.startDate),
            endDate: (0, parsers_2.nullableTransformDate)(semester.attributes.dates.endDate),
            isActive: semester.attributes.isActive
        };
    });
};
exports.parseSemestersForSelect = parseSemestersForSelect;
